import React from "react";
import { CFooter } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import LanguageSwitcher from "src/common/LanguageSwitcher";
import { setRtdbLanguage } from "src/feature/erpnextAPI/actions";
const TheFooter = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const updateRtdbLanguage = (lang) => {
    dispatch(setRtdbLanguage(auth.user.email, lang));
  };
  return (
    <CFooter
      fixed={false}
      style={{
        backgroundColor: "#fff",
        height: "auto",
      }}
    >
      <div className="mx-auto text-center">
        <a
          href="https://italjapan.it"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "black",
            fontSize: "12px",
          }}
        >
          Ver 1.4.36 Italjapan S.r.l. - Via Caracciolo 15 - 80122 Napoli (NA) ITALY P.IVA/C.F. - IT083986791217 - Nr. REA: NA 953805. - Cap. Soc.: EUR 60.000,00
        </a>{" "}
        <LanguageSwitcher updateRtdbLanguage={updateRtdbLanguage} />
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
